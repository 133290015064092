import * as React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import PrivateRoute from './components/security/PrivateRoute'
import { Link } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Dashboard from './pages/dashboard/Dashboard'
import Tutorial from './pages/dashboard/Tutorial'
import Guide from './Guide'
import Course from './Course'
import Trainers from './pages/trainers/Trainers'
import SignIn from './SignIn'
import SignUp from './SignUp'
import Profile from './pages/students/Profile'
import ToolbarDrawer from './components/drawer/ToolbarDrawer'
import StudentDashboard from './pages/students/StudentDashboard'
import Student from './pages/students/Student'
import VideoPage from './pages/videos/VideoPage'
import VideoListing from './pages/videos/VideoListing'
import Lesson from './pages/workouts/Workout'
import LessonEditOrCreate from './pages/workouts/WorkoutEdit'
import WorkoutGen from './pages/workoutsGenerator/WorkoutGen'
import SlicePage from './pages/slices/SlicePage'
import Slices from './pages/slices/Slices'
import Step1 from './pages/intro/Step1'
import Step2 from './pages/intro/Step2'
import Step3 from './pages/intro/Step3'
import Step4 from './pages/intro/Step4'
import Step5 from './pages/intro/Step5'
import Step6 from './pages/intro/Step6'
import Step7 from './pages/intro/Step7'
import Step8 from './pages/intro/Step8'
import Step9 from './pages/intro/Step9'
import StudentCoachView from './pages/students/StudentCoachView'
import PlanSelection from './Plan'
import { useSession } from './UserProvider'
import { useEffect } from 'react'
import Handbook from './pages/handbook/Handbook'

function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}>
      {'Copyright © '}
      <Link color='inherit' to='#'>
        VocalBrain Inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

function Layout() {
  return (
    <Box
      component='main'
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <ToolbarDrawer />
        <Box
          component='main'
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}>
          <Toolbar />
          <Routes>
            <Route path='/' element={<PrivateRoute />}>
              <Route path='/' element={<Dashboard />} />
              <Route path='/guide' element={<Guide />} />
              <Route path='/course/:id' element={<Course />} />
              <Route path='/slices' element={<Slices />} />
              <Route path='/slice/:id' element={<SlicePage />} />
              <Route path='/trainers' element={<Trainers />} />
              <Route path='/workout' element={<WorkoutGen />} />
              <Route path='/profile' element={<Profile />} />
              <Route path='/plan' element={<PlanSelection />} />
              <Route path='/students' element={<StudentDashboard />} />
              <Route path='/student' element={<Student />} />
              <Route path='/student/:id' element={<Student />} />
              <Route
                path='/student-coach/:studentId'
                element={<StudentCoachView />}
              />
              <Route path='/lesson-edit/:id' element={<LessonEditOrCreate />} />
              <Route path='/lesson/:id' element={<Lesson />} />
              <Route path='/videos' element={<VideoListing />} />
              <Route path='/video/:documentId' element={<VideoPage />} />
              <Route path='/tutorial/:tutorialId' element={<Tutorial />} />
              <Route path='/handbook/:tutorialId' element={<Handbook />} />
              <Route path='/intro/step-1' element={<Step1 />} />
              <Route path='/intro/step-2' element={<Step2 />} />
              <Route path='/intro/step-3' element={<Step3 />} />
              <Route path='/intro/step-4' element={<Step4 />} />
              <Route path='/intro/step-5' element={<Step5 />} />
              <Route path='/intro/step-6' element={<Step6 />} />
              <Route path='/intro/step-7' element={<Step7 />} />
              <Route path='/intro/step-8' element={<Step8 />} />
              <Route path='/intro/step-9' element={<Step9 />} />
            </Route>
            <Route path='/sign-in' element={<SignIn />} />
            <Route path='/sign-up' element={<SignUp />} />
            <Route path='/course-embed/:id' element={<Course />} />
          </Routes>
          <ToastContainer />
          <Copyright sx={{ pt: 4 }} />
        </Box>
      </Box>
    </Box>
  )
}

export default function App() {
  const { user, isAppAdmin, isCoach, isPremium } = useSession()
  const navigate = useNavigate()

  useEffect(() => {
    if (user && !isPremium && !isAppAdmin && !isCoach) {
      navigate('/plan')
    }
  }, [user, isPremium, isAppAdmin, isCoach, navigate])

  return <Layout />
}
