import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase/config'; // Adjust this path based on your project structure
import {
  Box,
  Button,
  Typography,
  Container,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
} from '@mui/material';
import SoundSlicePlayer from '../../components/soundslice/SoundSlicePlayer';
import {
  doc,
  getDoc,
  collection,
  query,
  orderBy,
  getDocs,
} from 'firebase/firestore';
import { trackActivity } from '../../components/util/Helpers';
import { useSession } from '../../UserProvider';

const StepWorkout = () => {
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [slices, setSlices] = useState([]);
  const { user } = useSession();

  useEffect(() => {
    const fetchSlices = async () => {
      if (id) {
        const slicesRef = collection(db, 'workouts', id, 'slices');
        const q = query(slicesRef, orderBy('order'));
        const querySnapshot = await getDocs(q);

        const slicesDataWithVariants = await Promise.all(
          querySnapshot.docs.map(async (sliceDoc) => {
            const sliceData = sliceDoc.data();
            const sliceId = sliceData.soundSliceId_Ext;
            console.log(sliceId);

            // Check if sliceId is defined
            if (!sliceId) {
              console.error('sliceId is undefined for a document', sliceDoc.id);
              return {
                ...sliceData,
                id: sliceDoc.id,
                variants: [], // Provide an empty array as default
              };
            }

            const sliceRef = doc(db, 'slices', sliceId);
            const sliceSnapshot = await getDoc(sliceRef);
            const sliceVariantData = sliceSnapshot.exists()
              ? sliceSnapshot.data()
              : null;

            // Check if sliceVariantData and its variants are defined
            if (
              !sliceVariantData ||
              !Array.isArray(sliceVariantData.variants)
            ) {
              console.error(
                'Variants are undefined or not an array for sliceId',
                sliceId
              );
              return {
                ...sliceData,
                id: sliceDoc.id,
                variants: [], // Provide an empty array as default
              };
            }

            return {
              ...sliceData,
              id: sliceId,
              variants: sliceVariantData.variants,
            };
          })
        );

        setSlices(slicesDataWithVariants);
      }
    };

    fetchSlices();
  }, [id, db]);

  const handleNext = () => {
    trackActivity(user.uid, slices[activeStep].name);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const renderSliceContent = (slice) => {
    switch (slice.type) {
      case 'video':
        return (
          <Box style={{ marginBottom: '2em' }}>
            <iframe
              width="560"
              height="315"
              src={slice.url}
              title={slice.name}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Box>
        );
      case 'text':
        return (
          <Box style={{ marginBottom: '2em' }}>
            <Typography variant="body1">{slice.text}</Typography>
          </Box>
        );
      default:
        return (
          <Box style={{ marginBottom: '2em' }}>
            <SoundSlicePlayer sliceData={slice} />
          </Box>
        );
    }
  };

  console.log('__WORKOUTS__', slices);

  return (
    <Container>
      <Stepper activeStep={activeStep} orientation="vertical">
        {slices.map((slice, index) => (
          <Step key={slice.id + index.toString()}>
            <StepLabel>{slice.name}</StepLabel>
            <StepContent>
              <div>
                <div>
                  {slice.notes ? (
                    <React.Fragment>{slice.notes}</React.Fragment>
                  ) : (
                    <React.Fragment> </React.Fragment>
                  )}
                </div>
              </div>

              {renderSliceContent(slice)}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {activeStep === slices.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === slices.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you're finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Start Over
          </Button>
        </Paper>
      )}
    </Container>
  );
};

export default StepWorkout;