import React, { useState, useEffect } from 'react'
import { db } from '../../firebase/config'
import { collection, addDoc, updateDoc, doc, getDocs } from 'firebase/firestore'
import {
  Modal,
  Box,
  Typography,
  TextField,
  Slider,
  Button,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material'
import { toast } from 'react-toastify'

function CustomSliceModal({
  open,
  onClose,
  workoutId,
  onSliceAdded,
  maxOrder,
  editableSlice,
}) {
  const [slices, setSlices] = useState([])
  const [selectedSliceId, setSelectedSliceId] = useState(
    editableSlice ? editableSlice.soundSliceId_Ext : ''
  )
  const [notes, setNotes] = useState(editableSlice ? editableSlice.notes : '')
  const [startNote, setStartNote] = useState(
    editableSlice ? editableSlice.startNote : 0
  )
  const [key, setKey] = useState(editableSlice ? editableSlice.key : 'C')
  const [filterLabel, setFilterLabel] = useState('All')
  const allowedLabels = ['All', 'Chest', 'Breath', 'Head', 'Mix']

  const [vocalRange, setVocalRange] = useState('LOW')

  useEffect(() => {
    const localStorageVocalRange =
      localStorage.getItem('studentVocalRange') ||
      localStorage.getItem('vocalRange')
    setVocalRange(localStorageVocalRange || 'LOW')
  }, [])

  useEffect(() => {
    const fetchSlices = async () => {
      const querySnapshot = await getDocs(collection(db, 'slices'))
      const fetchedSlices = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
        labels: doc.data().labels || [],
        variants: doc.data().variants || [],
      }))
      setSlices(fetchedSlices.sort((a, b) => a.name.localeCompare(b.name)))

      if (editableSlice) {
        setSelectedSliceId(editableSlice.soundSliceId_Ext)
        setNotes(editableSlice.notes)
        setStartNote(editableSlice.startNote)
        setKey(editableSlice.key || 'C') // Ensure key is defined
      } else if (fetchedSlices.length > 0) {
        setSelectedSliceId(fetchedSlices[0].id)
        const variant = fetchedSlices[0].variants.find(
          (v) => v.type === vocalRange.toLowerCase()
        )
        const defaultKey = variant ? variant.defaultKey.replace(/\d/, '') : 'C'
        setKey(defaultKey)
        setStartNote(0)
      }
    }
    if (open) {
      fetchSlices()
    }
  }, [open, editableSlice, vocalRange])

  const handleLabelChange = (event) => {
    setFilterLabel(event.target.value)
    setSelectedSliceId('') // Reset selected slice when filter changes
  }

  const handleSliceChange = (event) => {
    const newSelectedSliceId = event.target.value
    setSelectedSliceId(newSelectedSliceId)
    const selectedSlice = slices.find(
      (slice) => slice.id === newSelectedSliceId
    )
    if (selectedSlice) {
      const variant = selectedSlice.variants.find(
        (v) => v.type === vocalRange.toLowerCase()
      )
      const defaultKey = variant ? variant.defaultKey.replace(/\d/, '') : 'C'
      setKey(defaultKey)
      setStartNote(0) // Reset startNote when a new slice is selected
      console.log(`Changed to Slice: ${selectedSlice.name}`)
      console.log(`Vocal Range: ${vocalRange}`)
      console.log(`Selected Variant: `, variant)
      console.log(`Default Key: ${defaultKey}`)
    }
  }

  const getNotesForExerciseKey = () => {
    const baseNotes = [
      'C',
      'C♯/D♭',
      'D',
      'D♯/E♭',
      'E',
      'F',
      'F♯/G♭',
      'G',
      'G♯/A♭',
      'A',
      'A♯/B♭',
      'B',
    ]
    const cleanKey = key ? key.replace(/\d/, '') : 'C' // Ensure key is defined
    const offset = baseNotes.indexOf(cleanKey)
    console.log(`Clean Key: ${cleanKey}, Offset: ${offset}`)
    return baseNotes.map((note, index) => baseNotes[(index + offset) % 12])
  }

  const translateNote = (note) => {
    const notesArray = getNotesForExerciseKey()
    console.log(`Translating note: ${note}, Notes Array: ${notesArray}`)
    if (notesArray.length === 0) return ''
    const index = (note + 12) % 12
    return notesArray[index]
  }

  const ValueLabelComponent = (props) => {
    const { children, open, value } = props
    const note = translateNote(value)
    console.log(`ValueLabelComponent - Note: ${note}`)
    return (
      <Tooltip open={open} enterTouchDelay={0} placement='top' title={note}>
        {children}
      </Tooltip>
    )
  }

  const handleAddOrUpdateSlice = async () => {
    if (!selectedSliceId) {
      toast.error('Please select a slice.')
      return
    }
    const fullSlice = slices.find((s) => s.id === selectedSliceId)
    if (!fullSlice) {
      toast.error('Selected slice not found.')
      return
    }

    if (!key) {
      toast.error('Key is undefined.')
      return
    }

    const sliceData = {
      createdAt: new Date(),
      key: key,
      labels: fullSlice.labels,
      name: fullSlice.name,
      notes: notes,
      order: editableSlice ? editableSlice.order : maxOrder + 1,
      startNote: startNote,
      soundSliceId_Ext: selectedSliceId,
      variants: fullSlice.variants,
    }

    try {
      console.log(workoutId);
      debugger;
      const sliceRef = editableSlice
        ? doc(db, `workouts/${workoutId}/slices`, editableSlice.id)
        : collection(db, `workouts/${workoutId}/slices`)
      const action = editableSlice
        ? updateDoc(sliceRef, sliceData)
        : addDoc(sliceRef, sliceData)
      await action
      onClose(true)
      onSliceAdded()

      toast.success(
        editableSlice
          ? 'Exercise updated successfully!'
          : 'Exercise added successfully!'
      )
      // Reset startNote and key after successfully adding or updating a slice
      if (!editableSlice) {
        setStartNote(0)
        setKey('C')
        setNotes('')
      }
    } catch (error) {
      toast.error('Error updating slice in lesson.')
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby='modal-title'>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'auto',
          maxWidth: '90vw',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          outline: 'none',
        }}>
        <Typography id='modal-title' variant='h6' sx={{ mb: 2 }}>
          {editableSlice
            ? 'Edit Exercise in Workout'
            : 'Add Exercise to Workout'}
        </Typography>
        <FormControl fullWidth margin='normal'>
          <Select
            labelId='filter-label-select-label'
            id='filter-label-select'
            value={filterLabel}
            onChange={handleLabelChange}>
            {allowedLabels.map((label) => (
              <MenuItem key={label} value={label}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin='normal'>
          <Select
            labelId='slice-id-select-label'
            id='slice-id-select'
            value={selectedSliceId}
            onChange={handleSliceChange}>
            {slices
              .filter(
                (slice) =>
                  filterLabel === 'All' || slice.labels.includes(filterLabel)
              )
              .map((slice) => (
                <MenuItem key={slice.id} value={slice.id}>
                  {slice.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          label='Notes'
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          margin='normal'
          fullWidth
          multiline
          rows={4}
        />
        <Slider
          value={startNote}
          step={1}
          marks
          min={-7}
          max={7}
          onChange={(_, newValue) => setStartNote(newValue)}
          valueLabelDisplay='on'
          aria-labelledby='start-note-slider'
          components={{
            ValueLabel: ValueLabelComponent,
          }}
        />
        <Button
          variant='contained'
          onClick={handleAddOrUpdateSlice}
          sx={{ mt: 2 }}>
          {editableSlice ? 'Update Exercise' : 'Add Exercise'}
        </Button>
      </Box>
    </Modal>
  )
}

export default CustomSliceModal
